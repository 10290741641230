@import './shared/styles/_vuetify-overrides.scss';






































































































































































































.cassie-froala-title {
	color: #ff5252;
}

.cassie-froala-editor {
	.fr-toolbar {
		border: 2px solid #ff5252 !important;
		border-bottom: none !important;

		.fr-command.fr-btn svg path {
			fill: #ff5252;
		}
	}

	.fr-box.fr-basic .fr-wrapper {
		border: 2px solid #ff5252 !important;
		border-bottom: none !important;
	}

	.fr-second-toolbar {
		border: 2px solid #ff5252 !important;
		border-top: 0 !important;
	}
}

.cassie-hidden-validation-field {
	padding-top: 0;
	margin-top: 0;

	input {
		height: 0px !important;
		padding: 0px;
		visibility: hidden;
	}

	.v-input__control > .v-input__slot:before {
		border: none;
	}
}
.draggable-placeholder {
	cursor: grab;
	background-color: #f0f0f0;
	margin: 5px 10px 5px 0;
	border-radius: 4px;
	display: inline-block;
}

.draggable-placeholder:hover {
	background-color: #e0e0e0;
}
.draggable-placeholder:not(:last-child) {
	margin-right: 10px;
}
